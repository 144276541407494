const themesColors = [
    'hsl(0 60% 50%)',
    'hsl(144 60% 50%)',
    'hsl(60 70% 60%)',
    'hsl(257 60% 50%)',
    'hsl(14 60% 70%)',
    'hsl(99 28% 50%)',
    'hsl(27 60% 50%)',
    'hsl(288 70% 40%)',
]

export default themesColors; 